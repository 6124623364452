<template>
  <div>
    <header  style="position: relative;">
      <div style=" margin: 0 auto;">
          <img style="width: 100%;height: 100%;object-fit: contain;" src="../assets/images/bossnews/Group 2175.png" />
      </div>
      <div style="position: absolute;top: 150px;left: 300px;">
        <div></div>
      </div>
      <div class="boss-content" style="z-index: 9;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;">
            <div style="position: relative;left: 58px">
              <div class="boss-content">
                <h1 style="font-size: 50px;"><b>壹公里动态</b></h1>
                <p style="font-size: 20px;">及时了解数字化转型的行业方向</p>
                <br>
                <el-button class="image-button" @click="router()">方案咨询<img src='../assets/images/home_slices/arrowhead.png' style="margin-left: 30px;height: 15px;margin-bottom: 3px"></el-button>
              </div>
            </div>
      </div>
    </header>
    <main class="main">

      <div class="boss-content">
        <el-row :gutter="40" style="margin-top: 80px;">
          <el-col :xs="12" :md="12" style="">
            <div class="grid-content bg-purple" @click="toUrl(datas[0].url)"
              style="box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);height: 390px;  cursor: pointer;">
              <img style="height: 200px;width: 480px;" :src="datas[0].imagePath ? $imgUrl + datas[0].imagePath : ''">
              <div style="height: 60px;margin-left: 20px;margin-right: 20px;margin-top: 20px">
                <h5>{{ datas[0].title }}</h5>
              </div>
              <div style="margin-left: 20px;margin-right: 20px; height: 70px;">
                <p>{{ datas[0].description }}</p>
              </div>
              <div style="display: flex;align-items: center;margin-left: 20px;">
                <p>{{ datas[0].time }}</p>
              </div>
            </div>
          </el-col>
          <el-col :xs="12" :md="12">
            <div class="grid-content bg-purple" @click="toUrl(datas[1].url)"
              style="box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);height: 390px;  cursor: pointer;">
              <img style="height: 200px;width: 480px;" :src="datas[1].imagePath ? $imgUrl + datas[1].imagePath : ''">
              <div style="height: 60px;margin-left: 20px;margin-right: 20px;margin-top: 20px">
                <h5>{{ datas[1].title }}</h5>
              </div>
              <div style="margin-left: 20px;margin-right: 20px; height: 70px;">
                <p>{{ datas[1].description }}</p>
              </div>
              <div style="display: flex;align-items: center;margin-left: 20px;">
                <p>{{ datas[1].time }}</p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <div style="margin-top: 80px;" class="boss-content">
        <div style=" margin: 0 auto;box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05); margin-bottom: 50px;">
          <el-row>
            <el-col :xs="24" :md="24" v-for="(item, i) in datas" :key="i">
              <div class="grid-content bg-purple" @click="toUrl(item.url)" style="cursor: pointer;">
                <el-row style="
                  
                  height: 100px;
                  padding: 10px 0px 0px 10px;
                ">
                  <el-col :xs="6" :md="6">
                    <img style="height: 100px;width: 230px;" :src="item.imagePath ? $imgUrl + item.imagePath : ''" />
                  </el-col>
                  <el-col :xs="18" :md="18">
                    <div style="height: 70px;display: flex;align-items: center;margin-left: 20px;">
                      <h5>{{ item.title }}</h5>
                    </div>
                    <div style="display: flex;align-items: center;margin-left: 20px;">
                      <p>{{ item.time }}</p>
                    </div>
                    <el-divider></el-divider>
                  </el-col>

                </el-row>
              </div>

            </el-col>
          </el-row>
          <div  style="height: 100px;margin-top: 50px;">
            <el-pagination layout="prev, pager, next,total,jumper" :current-page="this.queryInfo.pagenum"
              :page-size="this.queryInfo.pagesize" :total="this.queryInfo.total" @current-change="handleCurrentChange"
              style="display: flex; justify-content: center; border-bottom: none;">
            </el-pagination>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  components: {},
  data() {
    return {
      queryInfo: {
        query: "",
        pagenum: 1,
        pagesize: 6,
        total: 0,
      },
      datas: [], //文章
    };
  },
  computed: {},
  watch: {},
  methods: {
    router() {
      this.$router.push("/apply/");
    },

    toUrl(val) {
      window.open(val);
    },
    docs(val) {
      this.$router.push("/quarterlyFile/programme/" + val.id);
    },
    handleCurrentChange(val) {
      this.queryInfo.pagenum = val;
      this.getData();
    },
    TimestampToDate(val) {
      // console.log('time',val)
      // let date1 = new Date(val);
      // let newTime=date1.toLocaleDateString() + " " + date1.toTimeString().substr(0, 8);
      // 创建 Date 对象
      const date = new Date(val);
      // 提取年、月、日、时、分
      const year = date.getUTCFullYear();
      const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // 月份从 0 开始，需要加 1，然后补齐两位
      const day = date.getUTCDate().toString().padStart(2, '0'); // 补齐两位
      const hours = date.getUTCHours().toString().padStart(2, '0'); // 补齐两位
      const minutes = date.getUTCMinutes().toString().padStart(2, '0'); // 补齐两位
      // 构造年月日时分格式
      // const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
      // console.log(`${year}-${month}-${day} ${hours}:${minutes}`);
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
    // TimestampToDate(val) {
      //let date1 = new Date(val);
      // return date1.toLocaleDateString() + " " + date1.toTimeString().substr(0, 8);
    // },
    async getData() {
      let _this = this;
      await _this.$http
        .get(`/getAllNews`, { params: _this.queryInfo })
        .then((e) => {
          if (e.data.code == 200) {
            this.queryInfo.total = e.data.data.total;
            this.datas = [];
            for (let index = 0; index < e.data.data.product.length; index++) {
              const element = e.data.data.product[index];
              let time = this.TimestampToDate(element.time);
              element.time = time.substring(0, 16);
              this.datas.push(element);

            }
          }
        });
      // 动画重启
      this.$nextTick(() => {
        // 在dom渲染完后,再执行动画
        var wow = new WOW({
          live: false,
        });
        wow.init();
      });
    },
  },
  created() { },
  mounted() {
    this.getData();
  },
  beforeCreate() { },
  beforeMount() { },
  beforeUpdate() { },
  updated() { },
  beforeDestroy() { },
  destroyed() { },
  activated() { },
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.image-button {
  background: linear-gradient(to right, #2840d4, #399ff7);
  color: white;
  width: 180px;
  height: 50px;
  outline: none;
}
.image-button:focus{
  outline: none;
  box-shadow: none;
  outline-color: transparent;
}
.el-divider--horizontal {
  margin: 0 0;
  width: 90%;
  height: 1px;
}

.bg-purple {
  background: white;
}

.grid-content {
  min-height: 50px;
}

.title {
  height: 160px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  background: url("../assets/images/bossnews/Group 2175.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 500px;
  position: relative;
  z-index: 1;
}
</style>